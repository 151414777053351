<!-- 出库 -->
<template>
  <div class="page">
    <template v-if="!disblesImg">
      <div class="pageTop">
        <div class="top">
          <Form inline class="formMarginBtm20">
            <!--          <FormItem>-->
            <!--            <span>客户名称：</span>-->
            <!--            <Select class="iviewIptWidth250 marginRight60" v-model="supplierIndex" clearable>-->
            <!--              <Option v-for="(item, index) in clientNameList" :value="index" :key="index">{{ item.customer_name }}</Option>-->
            <!--            </Select>-->
            <!--          </FormItem>-->
            <!--          <FormItem>-->
            <!--            <span>发货仓库：</span>-->
            <!--            <Select ref="name_select" placeholder="请选择" v-model="warehouse_id" clearable class="iviewIptWidth250">-->
            <!--              <Option v-for="(item, index) in thirdList" :value="item.dept_id" :key="index">{{ item.dept_name }}</Option>-->
            <!--            </Select>-->
            <!--          </FormItem>-->
            <FormItem>
              <span>拣货单号：</span>
              <i-input id="sstj1" class="iviewIptWidth250 marginRight60" placeholder="请输入" v-model="queryModal1.order_number"></i-input>
            </FormItem>
            <FormItem>
              <span>单据时间：</span>
              <DatePicker id="sstj2" :editable="false" type="daterange" placement="bottom-end" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250" @on-change="queryTimeChange"></DatePicker>
            </FormItem>
            <!--          <FormItem class="button" :label-width="10">-->
            <!--            <span class="pageBtn finger btnSure" @click="query">查询</span>-->
            <!--          </FormItem>-->
            <FormItem class="button" :label-width="10">
              <span id="gljhd_btn" class="longTextBtn finger btnAdd" @click="query">关联拣货单</span>
              <span id="dtbt_btn" class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
            </FormItem>
          </Form>
        </div>
        <div class="btm">
          <div class="btmRight">
            <p>待出库明细清单</p>
            <TableCom :rowClass="rowClassName" :productList="listColumns" :productData="listData" :option_page="option_page" border :style="{ 'min-height': listData.length == 0 ? '0px' : '500px' }">
              <template slot-scope="{ row, index }" slot="bar_code">
                <i-input :id="'bar_code' + index" placeholder="" :value="row.bar_code" @on-change="changeIpt($event, 'bar_code', index)" readonly></i-input>
              </template>
              <template slot-scope="{ row, index }" slot="batch_number">
                <i-input :id="'batch_number' + index" placeholder="请输入" :value="row.batch_number" @on-change="changeIpt($event, 'batch_number', index)" readonly></i-input>
              </template>
              <template slot-scope="{ row, index }" slot="serial_number">
                <i-input :id="'serial_number' + index" placeholder="请输入" :value="row.serial_number" @on-change="changeIpt($event, 'serial_number', index)" readonly></i-input>
              </template>
              <template slot-scope="{ row, index }" slot="quantity">
                <i-input :id="'quantity' + index" placeholder="请输入" type="number" :value="row.quantity" @on-change="changeIpt1($event, 'quantity', index)" readonly></i-input>
              </template>
              <template slot-scope="{ row, index }" slot="unit_price">
                <i-input :id="'unit_price' + index" placeholder="请输入" type="number" :value="row.unit_price" maxlength="8" @on-change="changeIpt1($event, 'unit_price', index)" readonly></i-input>
              </template>
              <template slot-scope="{ row, index }" slot="validity">
                <DatePicker :id="'validity' + index" :editable="false" placeholder="请选择" type="date" format="yyyy-MM-dd" :value="row.valid_period" @on-change="timeChange($event, index)" readonly></DatePicker>
              </template>
            </TableCom>
            <!-- <div style="position: relative; border: 1px solid #e8eaec; height: 450px; border-top: none !important;" class="clearfix noData" v-if="listData.length == 0">
            <img style="margin-top: 100px;" src="../../../assets/images/tableNoData.png" />
          </div> -->
            <div class="tipsDiv">
              <div class="tipsFl">备注</div>
              <div class="tipsFr">
                <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" v-model="postFrom.note"></i-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pageBtm">
        <span id="qrch" class="longTextBtn finger btnSure marginLeft20" @click="surePost">确认出库</span>
      </div>
    </template>
    <div class="disableImage" v-if="disblesImg">
      <div class="disableDiv">
        <img src="../../../assets/images/disableImg.png" alt="" />
        <span>当月已关账，不可操作库存</span>
      </div>
    </div>
    <!--    弹窗选择数据-->
    <Modal v-model="chooseStatus" label-position="left" :footer-hide="true" class-name="vertical-center-modal" title="产品列表">
      <div v-if="chooseStatus">
        <Table :columns="chooseColumns" :data="chooseList" border @on-selection-change="selectChange" height="500"></Table>
        <div class="clearfix" style="margin-top: 20px;">
          <span id="tc_qr" class="pageBtn finger btnSure fr" @click="sureChoose">确定</span>
        </div>
      </div>
    </Modal>
    <!-- 拣货单 -->
    <Modal v-model="picking" :mask-closable="false" width="80" class="modal">
      <p slot="header" class="header">拣货单</p>
      <div class="body">
        <Form inline class="formMarginBtm20"> </Form>
        <table-com class="table" @on-selection-change="changeSelection" :productList="pickingColumns" :productData="pickingData" :pages="queryModal1" :total="total" totalText="条记录" @change-page="changePage"></table-com>
      </div>
      <div slot="footer">
        <span class="longTextBtn finger btnSure marginLeft20" @click="sureChoose">确定</span>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="lastPost">
      <slot>
        <div class="moreNum" v-if="moreNum">
          <span style="color: rgb(250, 62, 59);">{{ moreNum }}</span
          >个产品拣货数量超出订单数量
        </div>
        <div class="unmoreNum" v-if="unmoreNum">
          <span style="color: rgb(46, 169, 9);">{{ unmoreNum }}</span
          >个产品拣货数量低于订单数量
        </div>
      </slot>
    </tips-component>

    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import TableSetup from '@/components/tableSetup'
import TableCom from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
import { mapState } from 'vuex'
export default {
  name: 'outStorageConsign',
  components: {
    TableCom,
    TipsComponent,
    TableSetup,
  },
  inject: ['reload'],
  computed: {
    ...mapState(['clientNameList']),
  },
  data() {
    return {
      disblesImg: false,
      titleList: [],
      option_page: '18',
      setupStatus: false,
      moreNum: 0,
      unmoreNum: 0,
      deleteStatus: false,
      postStatus: false,
      warehouse_id: '',
      // 销售退货通知单
      supplierIndex: -1,
      picking: false,
      pickingColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '单据时间',
          key: 'creatTime',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '拣货单号',
          key: 'order_number',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '产品',
          key: 'product_list',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
        },
        {
          title: '金额',
          key: 'total_amount',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.total_amount, true))
          },
        },
        // {
        //   title: '客户名称',
        //   key: 'customerName',
        //   align: 'center',
        // },
        {
          title: '仓库',
          key: 'warehouse_name',
          align: 'center',
          minWidth: 180,
        },
        // {
        //   title: '操作',
        //   align: 'center',
        //   width: 128,
        //   render: (h, param) => {
        //     return h('div', [
        //       h(
        //         'span',
        //         {
        //           style: {
        //             color: '#389AFC',
        //             cursor: 'pointer',
        //           },
        //           class: 'onlyonce' + param.index,
        //           on: {
        //             click: () => {
        //               this.chooseRow(param.row)
        //             },
        //           },
        //         },
        //         '确认'
        //       ),
        //     ])
        //   },
        // },
      ],
      pickingData: [],
      chooseStatus: false,
      chooseList: [],
      chooseColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 55,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 150,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 120,
        },
        {
          title: '销售单价',
          key: 'unit_price',
          align: 'center',
          width: 120,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          width: 70,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 70,
        },
        {
          title: '包装规格',
          key: 'packing_size',
          align: 'center',
        },
      ],
      // 加载中
      isLoad: false,
      // 分页
      total: 1,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          fixed: 'left',
          width: 65,
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 200,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 220,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          minWidth: 300,
        },
        {
          title: '条形码',
          slot: 'bar_code',
          align: 'center',
          minWidth: 350,
        },
        {
          title: '批号',
          slot: 'batch_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '序列号',
          slot: 'serial_number',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '数量',
          slot: 'quantity',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '销售单价',
          slot: 'unit_price',
          align: 'center',
          minWidth: 130,
        },
        {
          title: '有效期',
          slot: 'validity',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      hadSelectList: [],
      // 查询参数
      queryModal1: {
        page: 1,
        rows: 10,
        order_number: '',
        create_time_start: '',
        create_time_end: '',
        delivery_page: '1',
      },
      postFrom: {
        line_info: [],
        note: '',
        warehouse_id: '',
        warehouse_name: '',
      },
      flag: true,
      changeSelectionList: [],
      id_list: [],
    }
  },
  created() {
    this.queryOption()
    this.inventoryLook()
  },
  activated() {
    this.inventoryLook()
  },
  methods: {
    changeSelection(e) {
      if (e) {
        this.changeSelectionList = e
      } else {
        this.changeSelectionList = []
      }
    },
    // 新增界面禁用功能
    inventoryLook() {
      this.$http.get(this.$apiConsign.inventoryLook, {}).then(res => {
        if (res.data === 1) {
          this.disblesImg = true
        }
      })
    },
    // 有效期小于180标黄
    rowClassName(row, index) {
      if (row.warning) return 'orginRow'
      return ''
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http
        .get(this.$apiConsign.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          // this.queryStoreList()
        })
    },
    // 确认提交
    surePost() {
      if (this.listData.length == 0) {
        this.$Message.warning('请至少添加一组产品再提交')
        return null
      }
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i].quantity > this.listData[i].product_amount) {
          this.moreNum = this.moreNum + 1
        }
        if (this.listData[i].quantity < this.listData[i].product_amount) {
          this.unmoreNum = this.unmoreNum + 1
        }
      }
      this.postStatus = true
    },
    // 最终提交
    lastPost() {
      if (!this.flag) return
      this.flag = false
      let data = {
        id_list: this.id_list,
        note: this.postFrom.note,
      }
      this.$http
        .post(this.$apiConsign.warehousDeliveryBatch, data, false)
        .then(res => {
          this.flag = true
          this.$Message.success('出库成功')
          this.listData = []
          this.reload()
          this.postFrom.note = ''
          this.postStatus = false
        })
        .catch(err => {
          this.flag = true
        })
    },
    // 确认删除
    sureDelete() {
      this.listData.splice(this.deleteIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
      // if(this.listData.length == 0) {
      //   this.reload()
      // }
    },
    // 删除
    deleteRow(index) {
      this.deleteIndex = index
      this.deleteStatus = true
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      this.id_list = []
      if (!this.changeSelectionList.length) {
        this.$Message.warning('请至少选择一组产品')
        return
      }
      this.changeSelectionList.forEach((item, index) => {
        this.id_list.push(item.id)
      })
      let objData = {
        id_list: this.id_list,
      }
      this.$http.post(this.$apiConsign.pickingLine, objData, true).then(res => {
        this.listData = res.data
        this.listData.forEach((item, index) => {
          item.product_amount = item.quantity
          item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : null
          item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : null
          // 判断是录入的是年月还是年月日
          item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date).replace(/-/gi, '') : null
          item.warning = this.$utils.warningDay(item.valid_period, 180)
          item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : null
          // 判断是录入的是年月还是年月日
          item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period).replace(/-/gi, '') : null
          item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : null
          item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : null
          if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : null
          } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.business_license_valid_period = '长期'
          } else {
            item.business_license_valid_period = null
          }
          if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : null
          } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.record_valid_period = '长期'
          } else {
            item.record_valid_period = null
          }
          item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : null
          item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : null
          item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : null
          item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : null
          item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : null
          item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : null
          item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : null
        })
        this.chooseStatus = false
        this.picking = false
      })
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list || []
    },
    // 确认订单查详情
    chooseRow(row) {
      this.$http.get(this.$apiConsign.warehousePickingDetail, { id: row.id, delivery_page: '1' }, true).then(res => {
        let arr = res.data.warehouse_picking_id
        // for (let i = 0; i < arr.length; i++) {
        //   arr[i].model_name = arr[i].product_model_name
        //   arr[i].item_number = arr[i].product_item_name
        //   arr[i].unit = arr[i].product_unit
        //   arr[i].unit_price = arr[i].product_unit_price
        // }
        this.postFrom.warehouse_id = res.data.warehouse_id
        this.postFrom.warehouse_name = res.data.warehouse_name
        this.postFrom.note = res.data.note
        // this.chooseStatus = true
        // this.chooseList = JSON.parse(JSON.stringify(arr))
        this.hadSelectList = JSON.parse(JSON.stringify(arr))
        this.sureChoose()
      })
    },
    // 查询销售订单列表
    queeryModal1() {
      // if (this.supplierIndex1 >= 0) {
      //   this.queryModal1.customer_id = this.clientNameList[this.supplierIndex1].customer_id
      //   this.queryModal1.customer_type = this.clientNameList[this.supplierIndex1].customer_type
      // } else {
      //   this.queryModal1.customer_id = ''
      //   this.queryModal1.customer_type = ''
      // }

      // 小骆添加穿参数
      this.queryModal1.relate_picking = '1'
      // BugMan添加穿参数

      this.$http.get(this.$apiConsign.warehousePicking, this.queryModal1, true).then(res => {
        this.total = res.total
        this.picking = true
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].creatTime = res.data[i].create_time ? this.$moment(res.data[i].create_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
        this.pickingData = res.data
      })
    },
    // 关联
    openModal() {
      this.moreNum = 0
      this.unmoreNum = 0
      this.hadSelectList = []
      this.queeryModal1()
    },
    timeChange(e, index) {
      this.$set(this.listData[index], 'valid_period', e)
    },
    queryTimeChange(e) {
      if (e[0]) {
        this.queryModal1.create_time_start = e[0]
        this.queryModal1.create_time_end = e[1]
      } else {
        this.queryModal1.create_time_start = null
        this.queryModal1.create_time_end = null
      }
    },
    changePage(e) {
      this.queryModal1.page = e
      this.queeryModal1()
    },
    query() {
      this.moreNum = 0
      this.unmoreNum = 0
      this.hadSelectList = []
      this.queryModal1.page = 1
      this.queeryModal1()
    },
    // 输入框改变
    changeIpt(e, name, index) {
      this.$set(this.listData[index], name, e.target.value.trim())
    },
    changeIpt1(e, name, index) {
      this.moreNum = 0
      this.unmoreNum = 0
      if (e.target.value * 1 < 0) {
        this.$set(this.listData[index], name, 1)
        return null
      } else {
        this.$set(this.listData[index], name, e.target.value.trim() * 1)
        return null
      }
    },
  },
}
</script>

<style scoped lang="less">
/deep/ .ivu-modal {
  width: 1200px !important;
}
.marginRight60 {
  margin-right: 60px;
}
.page {
  display: flex;
  flex-direction: column;

  .pageTop {
    flex: 1;
    overflow-y: auto;

    .top {
      padding: 20px 22px 0;
    }

    .btm {
      flex: 1;
      display: flex;

      .btmLeft {
        width: 369px;
        padding: 23px;
        border-top: 1px solid #e8eaec;
        border-right: 1px solid #e8eaec;
        display: flex;
        flex-direction: column;

        p {
          margin-bottom: 23px;
          font-size: 18px;
          color: #525b6d;
        }

        .textarea {
          flex: 1;
          padding: 7px 15px;
          font-size: 16px;
        }
      }

      .btmRight {
        flex: 1;
        padding: 23px;
        border-top: 1px solid #e8eaec;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        p {
          margin-bottom: 23px;
          font-size: 18px;
          color: #525b6d;
        }

        .summary > span:first-child {
          margin-right: 30px;
        }

        .tipsDiv {
          margin-top: 20px;
          border: 1px solid #e8eaec;
          height: 230px;
          display: flex;
          .tipsFl {
            width: 210px;
            line-height: 230px;
            padding-left: 33px;
            border-right: 1px solid #e8eaec;
            background: #f8f8f9;
            color: #525b6d;
            font-size: 14px;
          }
          /deep/ .tipsFr {
            flex: 1;
            display: flex;
            padding: 8px 15px 8px 23px;
            .ivu-input-wrapper {
              height: 100% !important;
            }
            .ivu-input {
              height: 100% !important;
            }
          }
        }

        .button {
          height: 36px;
          line-height: 36px;
          text-align: right;
        }
      }
    }
  }

  .pageBtm {
    text-align: right;
    padding-top: 25px;
    padding: 20px 22px;
  }
}
.marginRight60 {
  margin-right: 77px;
}
.iviewIptWidth200 {
  width: 200px !important;
}
.marginRight23 {
  margin-right: 23px;
}
</style>
